import React, { useEffect, useState } from "react";
import axios from "axios";
import PastPredictionItem from "./PastPredictionItem";
import "../css/PredictionList.css";
import Footer from "./Footer";
import moment from "moment";

const PastPredictions = () => {
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterDate, setFilterDate] = useState("");
  const [filterCompetition, setFilterCompetition] = useState("");
  const [filterTeam, setFilterTeam] = useState("");
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Track mobile state

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/predictions/get_past_predictions`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const data = response.data.past_predictions || [];
        const transformedData = data.map((prediction) => ({
          ...prediction,
          competition: {
            ...(prediction.competition || {}),
            code: prediction.competition?.code ?? "",
          },
        }));

        setPredictions(transformedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching predictions:", error);
        setLoading(false);
      }
    };

    fetchPredictions();
  }, []);

  // Add a resize event listener to update the isMobile state
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleFilterDateChange = (e) => {
    setFilterDate(e.target.value);
  };

  const handleFilterCompetitionChange = (e) => {
    setFilterCompetition(e.target.value);
  };

  const handleFilterTeamChange = (e) => {
    setFilterTeam(e.target.value);
  };

  const handleSortChange = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const handleReset = () => {
    setFilterDate("");
    setFilterCompetition("");
    setFilterTeam("");
    setSortColumn(null);
    setSortOrder("asc");
  };

  const getFilteredAndSortedPredictions = () => {
    let filtered = [...predictions];

    if (filterDate) {
      filtered = filtered.filter(
        (prediction) =>
          moment(prediction.match_date).format("YYYY-MM-DD") === filterDate
      );
    }

    if (filterCompetition) {
      filtered = filtered.filter((prediction) =>
        (prediction?.competition?.code?.toLowerCase() ?? "").includes(
          filterCompetition.toLowerCase()
        )
      );
    }

    if (filterTeam) {
      filtered = filtered.filter((prediction) => {
        const homeTeamMatch =
          prediction?.home_team?.short_name
            ?.toLowerCase()
            ?.includes(filterTeam.toLowerCase()) ?? false;
        const awayTeamMatch =
          prediction?.away_team?.short_name
            ?.toLowerCase()
            ?.includes(filterTeam.toLowerCase()) ?? false;
        return homeTeamMatch || awayTeamMatch;
      });
    }

    if (sortColumn) {
      filtered.sort((a, b) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (sortColumn === "match_date") {
          const dateA = moment(aValue);
          const dateB = moment(bValue);
          return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        } else {
          const comparison = String(aValue).localeCompare(String(bValue));
          return sortOrder === "asc" ? comparison : -comparison;
        }
      });
    }
    return filtered;
  };

  const predictionsToDisplay = getFilteredAndSortedPredictions();

  if (loading) {
    return <p>Loading past predictions...</p>;
  }

  return (
    <div className="prediction-table-container">
      <h1>Past Predictions</h1>

      <div className="filter-container">
        <label htmlFor="filterDate">Filter by Date:</label>
        <input
          type="date"
          id="filterDate"
          value={filterDate}
          onChange={handleFilterDateChange}
        />

        <label htmlFor="filterCompetition">Filter by Competition:</label>
        <input
          type="text"
          id="filterCompetition"
          value={filterCompetition}
          onChange={handleFilterCompetitionChange}
        />

        <label htmlFor="filterTeam">Filter by Team:</label>
        <input
          type="text"
          id="filterTeam"
          value={filterTeam}
          onChange={handleFilterTeamChange}
        />

        <button onClick={handleReset}>Reset Filters</button>
      </div>

      {predictionsToDisplay.length === 0 ? (
        <p>No predictions found.</p>
      ) : (
        <table className="prediction-table">
          <thead>
            <tr>
              <th
                onClick={() => handleSortChange("match_date")}
                className="table-header-cell"
                style={{
                  fontSize: "0.8rem",
                  margin: "0",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                Date{" "}
                {sortColumn === "match_date" &&
                  (sortOrder === "asc" ? "▲" : "▼")}
              </th>
              {!isMobile && (
                <th
                  onClick={() => handleSortChange("competition")}
                  className="table-header-cell"
                  style={{
                    fontSize: "0.8rem",
                    margin: "0",
                    paddingLeft: "0",
                    paddingRight: "0",
                  }}
                >
                  Competition{" "}
                  {sortColumn === "competition" &&
                    (sortOrder === "asc" ? "▲" : "▼")}
                </th>
              )}
              <th
                className="table-header-cell"
                style={{
                  fontSize: "0.8rem",
                  margin: "0",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                Home
              </th>
              {!isMobile && <th className="vs-column">VS</th>}
              <th
                className="table-header-cell"
                style={{
                  fontSize: "0.8rem",
                  margin: "0",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                Away
              </th>
              <th
                className="table-header-cell"
                style={{
                  fontSize: "0.8rem",
                  margin: "0",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                {isMobile ? "Pred" : "Prediction"}
              </th>
              <th
                className="table-header-cell"
                style={{
                  fontSize: "0.8rem",
                  margin: "0",
                  paddingLeft: "0",
                  paddingRight: "0",
                }}
              >
                {isMobile ? "Result" : "Result"}
              </th>
            </tr>
          </thead>
          <tbody>
            {predictionsToDisplay.map((prediction) => (
              <PastPredictionItem
                key={prediction.match_id}
                prediction={prediction}
                hideVsColumn={isMobile}
              />
            ))}
          </tbody>
        </table>
      )}
      <Footer />
    </div>
  );
};

export default PastPredictions;
