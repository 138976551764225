import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
} from "react-router-dom";
import PredictionList from "./components/PredictionList";
import Login from "./components/Login";
import Statistics from "./components/Statistics";
import "./App.css";
import PastPredictions from "./components/PastPredictions";

function App() {
  const [authenticated, setAuthenticated] = useState(
    localStorage.getItem("access_token") ? true : false
  );

  return (
    <Router>
      <div>
        {authenticated && (
          <header className="app-header">
            <nav>
              <ul>
                <li>
                  <Link to="/">Predictions</Link>
                </li>
                <li>
                  <Link to="/statistics">Statistics</Link>
                </li>
                <li>
                  <Link to="/past-predictions">Past Predictions</Link>
                </li>
              </ul>
            </nav>
          </header>
        )}

        <Routes>
          <Route
            path="/"
            element={
              authenticated ? <PredictionList /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/statistics"
            element={authenticated ? <Statistics /> : <Navigate to="/login" />}
          />
          <Route
            path="/login"
            element={
              authenticated ? (
                <Navigate to="/" />
              ) : (
                <Login setAuthenticated={setAuthenticated} />
              )
            }
          />
          <Route
            path="/past-predictions"
            element={
              authenticated ? <PastPredictions /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
