import React from "react";
import moment from "moment";
import "../css/PredictionItem.css";

const PastPredictionItem = ({ prediction, isMobile }) => {
  const winnerColor =
    prediction.predicted_winner.short_name ===
    prediction.actual_winner.short_name
      ? "#4caf50"
      : "red";

  return (
    <tr className="prediction-row">
      <td className="prediction-cell date-cell">
        {moment(prediction.match_date).format("DD/MM")}
      </td>
      {!isMobile && (
        <td className="prediction-cell competition-cell">
          {prediction.competition.code}
        </td>
      )}
      <td className="prediction-cell team-cell">
        {prediction.home_team.short_name}
      </td>
      {!isMobile && <td className="prediction-cell vs-cell">vs</td>}
      <td className="prediction-cell team-cell">
        {prediction.away_team.short_name}
      </td>
      <td
        className="prediction-cell winner-cell"
        style={{ color: winnerColor }}
      >
        {prediction.predicted_winner.short_name}
      </td>
      <td className="prediction-cell winner-cell">
        {prediction.actual_winner.short_name}
      </td>
    </tr>
  );
};

export default PastPredictionItem;
