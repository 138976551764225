import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import "../css/Statistics.css";

const Statistics = () => {
  const [accuracyPercentage, setAccuracyPercentage] = useState(0);
  const [accuracyByCompetition, setAccuracyByCompetition] = useState([]);
  const [accuracyByPredictionPercentage, setAccuracyByPredictionPercentage] =
    useState([]);
  const [topTeams, setTopTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/statistics/get_statistics`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const data = response.data;
        setAccuracyPercentage(data.accuracy.percentage);

        // Transform accuracy_by_competition into an array for Recharts
        const formattedCompetitions = Object.keys(
          data.accuracy_by_competition
        ).map((key) => ({
          name: data.accuracy_by_competition[key].name,
          accuracy: data.accuracy_by_competition[key].percentage,
        }));

        setAccuracyByCompetition(formattedCompetitions);

        // Extract top 5 highest and lowest accuracy teams
        const highestAccuracy =
          data.accuracy_by_team.top_5_highest_accuracy.map(
            ([teamId, stats]) => ({
              name: stats.name,
              accuracy: stats.percentage,
            })
          );

        const lowestAccuracy = data.accuracy_by_team.top_5_lowest_accuracy.map(
          ([teamId, stats]) => ({
            name: stats.name,
            accuracy: stats.percentage,
          })
        );
        setTopTeams([...highestAccuracy, ...lowestAccuracy]);

        const formattedPredictionPercentages = Object.keys(
          data.accuracy_by_prediction_percentage
        ).map((key) => ({
          prediction: key,
          accuracy: data.accuracy_by_prediction_percentage[key].accuracy,
        }));

        console.log(formattedPredictionPercentages);

        setAccuracyByPredictionPercentage(formattedPredictionPercentages);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching statistics:", error);
        setError("Failed to fetch statistics.");
        setLoading(false);
      }
    };

    fetchStatistics();
  }, []);

  if (loading) {
    return <p>Loading statistics...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const COLORS = ["#18DB18", "#FF4D4D"];

  return (
    <div className="statistics-container">
      <h1>Accuracy Statistics</h1>

      <div className="chart-grid">
        <div className="chart-section">
          <h2>Overall Accuracy</h2>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={[
                  { name: "Correct", value: accuracyPercentage },
                  { name: "Incorrect", value: 100 - accuracyPercentage },
                ]}
                cx="50%"
                cy="50%"
                innerRadius={50}
                outerRadius={100}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {COLORS.slice(0, 2).map((color, index) => (
                  <Cell key={`cell-${index}`} fill={color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-section">
          <h2>Accuracy by Competition</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={accuracyByCompetition}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis
                dataKey="name"
                angle={-45}
                textAnchor="end"
                interval={0}
                height={100}
              />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="accuracy" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-section">
          <h2>Top 5 Highest & Lowest Accuracy Teams</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              layout="vertical"
              data={topTeams}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis type="number" domain={[0, 100]} />
              <YAxis dataKey="name" type="category" width={150} />
              <Tooltip />
              <Legend />
              <Bar dataKey="accuracy" fill="#FF8042" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-section">
          <h2>Accuracy by Prediction Percentage</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              data={accuracyByPredictionPercentage}
              margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <XAxis dataKey="prediction" />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="accuracy" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default Statistics;
