import React from "react";
import moment from "moment"; // Import moment for date formatting
import "../css/PredictionItem.css";

const PredictionItem = ({ prediction, isMobile }) => {
  const getProbabilityColor = (probability) => {
    if (probability < 0.5) {
      return "red";
    } else if (probability < 0.75) {
      return "orange";
    } else {
      return "green";
    }
  };

  const probabilityColor = getProbabilityColor(prediction.probability);

  return (
    <tr className="prediction-row">
      <td className="prediction-cell date-cell">
        {moment(prediction.match_date).format("DD/MM")}
      </td>
      {!isMobile && (
        <td className="prediction-cell competition-cell">
          {prediction.competition.code}
        </td>
      )}
      <td className="prediction-cell team-cell">
        {prediction.home_team.short_name}
      </td>
      {!isMobile && <td className="prediction-cell vs-cell">vs</td>}
      <td className="prediction-cell team-cell">
        {prediction.away_team.short_name}
      </td>
      <td
        className={`prediction-cell winner-cell ${prediction.winner.short_name}`}
      >
        {prediction.winner.short_name}
      </td>
      <td
        className="prediction-cell probability-cell"
        style={{ color: probabilityColor }}
      >
        {(prediction.probability * 100).toFixed(2)}%
      </td>
    </tr>
  );
};

export default PredictionItem;
